import {Link} from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import "../css/bootstrap.css";
import "../css/academicons.css";
// import {Link} from "gatsby-plugin-transition-link";

export default function Header ({siteTitle}) {
    let logo = null;
    if (siteTitle) {
        logo = <span className="logo h2"><span className="text-primary p-1">{siteTitle.split(
            " ")[0]}</span>
        <span className="text-white bg-dark p-1 ml-2">{siteTitle.split(
            " ")[1]}</span></span>
        // logo = <span className="logo text-primary p-1">{siteTitle}</span>
    }

    return (
        <header className="sticky-top  bg-light border-0">
            <nav
                className="navbar sticky-top navbar-expand-lg navbar-light w-100"
                style={{ paddingTop: 30, zIndex: 200 }}>
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        {logo}
                    </Link>

                    <button className="navbar-toggler me-3" type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbar_main">
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div className="collapse navbar-collapse justify-content-end"
                         id="navbar_main">
                        <ul className="navbar-nav mt-2 text-center">
                            <li className="nav-item mx-0 mx-md-3">
                                <Link className="nav-link"
                                      to="/" activeClassName="active">About
                                </Link>
                            </li>
                            <li className="nav-item mx-0 mx-md-3">
                                <Link className="nav-link"
                                      to="/research" activeClassName="active" partiallyActive={true}>Research
                                </Link>
                            </li>
                            <li className="nav-item mx-0 mx-md-3">
                                <Link className="nav-link"
                                    to="/projects" activeClassName="active">Projects
                                </Link>
                            </li>
                            <li className="nav-item mx-0 mx-md-3">
                                <Link className="nav-link"
                                      to="/other"
                                      activeClassName="active">
                                    Reviewing &amp; Teaching
                                    {/*Services*/}
                                </Link>
                            </li>
                        </ul>

                    </div>
               </div>
            </nav>

        </header>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: "",
};

// export default Header;
