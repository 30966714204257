/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import {withPrefix, useStaticQuery, graphql} from "gatsby";

function SEO({lang, title}) {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
    );

    return (
      <Helmet>
        <title>{title} | {site.siteMetadata.title}</title>
        {/*<link rel="stylesheet" href="static/bootstrap.min.css"/>*/}
        {/*<link rel="stylesheet" href="static/academicons.min.css"/>*/}
        <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js"
                integrity="sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r"
                crossOrigin="anonymous"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js"
                integrity="sha384-0pUGZvbkm6XF6gxjEnlmuGrJXVbNuzT9qBBavbLwCsOGabYfZo0T0to5eqruptLy"
                crossOrigin="anonymous"></script>
        <script src="https://kit.fontawesome.com/4d1f27b2f7.js"
                crossOrigin="anonymous"/>
        {/*<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet"*/}
        {/*      integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH"*/}
        {/*      crossOrigin="anonymous"/>*/}
        {/*<script src={withPrefix('common.js')} type="text/javascript"/>*/}
      </Helmet>
    );
}

SEO.defaultProps = {}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
}

export default SEO;
