/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql} from "gatsby";

import Header from "./header";
import Footer from "./footer";

const Layout = ({ omitTitle, children }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    let header = <Header siteTitle={data.site.siteMetadata.title} />
    if (omitTitle)
    {
        header = <Header />
    }

    return (
        <>
            <div className="container p-0">
                {header}
                <main>{children}</main>
                <Footer />
            </div>

        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
